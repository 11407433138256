import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';

const SupportedBy: React.FC = () => {
  const { translate } = useLanguage();

  return (
    <section id="supported-by" className="section bg-gray">
      <div className="container">
        <div className="logos">
          <img src="/assets/logo_nextai.png" alt="NextAI Logo" className="support-logo" />
          <img src="/assets/logo_centech.png" alt="Centech Accelerator Logo" className="support-logo" />
        </div>
        <p>{translate('supported_by_accel')}</p>
      </div>
    </section>
  );
};

export default SupportedBy;
