import React, { createContext, useState, useContext, ReactNode } from 'react';
import en from '../lang/en.json';
import fr from '../lang/fr.json';

interface LanguageContextProps {
  language: string;
  translate: (key: string) => string;
  toggleLanguage: () => void;
}

interface LanguageProviderProps {
  children: ReactNode; // Define children type
}

const translations: Record<string, Record<string, string>> = { en, fr };

const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  const [language, setLanguage] = useState<string>('en');

  const translate = (key: string): string => translations[language][key] || key;

  const toggleLanguage = (): void => {
    setLanguage((prev) => (prev === 'en' ? 'fr' : 'en'));
  };

  return (
    <LanguageContext.Provider value={{ language, translate, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): LanguageContextProps => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
