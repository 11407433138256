import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';

const ContactForm = () => {
  const { translate } = useLanguage();

  return (
    <div id="contact-form-overlay" className="overlay">
      <div className="overlay-content">
        <span id="close-overlay" className="close-btn">&times;</span>
        <h2>{translate('cta_title')}</h2>
        <form action="submit_contact.php" method="post">
          <label htmlFor="name">{translate('name')}</label>
          <input type="text" id="name" name="name" required />
          <label htmlFor="email">{translate('email')}</label>
          <input type="email" id="email" name="email" required />
          <label htmlFor="message">{translate('message')}</label>
          <textarea id="message" name="message" rows={5} required></textarea>

          <button type="submit" className="btn">{translate('get_started')}</button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
