import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';

const Features = () => {
  const { translate } = useLanguage();

  return (
    <section id="features" className="section bg-light">
      <div className="container">
        <h2>{translate('features_title')}</h2>
        <p>{translate('features_headline')}</p>
        <div className="features">
          {["action", "spotted", "timing", "mood", "connect"].map((num) => (
            <div className="feature" key={num}>
              <img src={`/assets/app_${num}.png`} alt="" />
              <h3>{translate(`feature_${num}_title`)}</h3>
              <p>{translate(`feature_${num}_content`)}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
