import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';

interface AboutProps {
  additionalInfo?: string;
}

const About: React.FC<AboutProps> = ({ additionalInfo }) => {
  const { translate } = useLanguage();

  return (
    <section id="about" className="section">
      <div className="container">
        <h2>{translate('about_title')}</h2>
        <p>{translate('about_content1')}</p>
        {additionalInfo && <p>{additionalInfo}</p>}
      </div>
    </section>
  );
};

export default About;
